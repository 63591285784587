(function($) {

  'use strict';

  // Variables
  var html = $('html');
  var header = $('.header');
  var url = location.pathname;
  var globalFooterToggle = $('.global-footer .footer__toggle');
  var globalFooterToggleButton = $('.global-footer .footer__toggle-button');
  var globalHeader = $('.global-header');
  var globalMobileMenuToggle = $('.global-header__toggle--menu');
  var globalSearchToggle = $('.global-header__toggle--search');
  var globalHeaderSearch = $('.global-header__search');
  var globalHeaderLink = $('.global-header__nav .list__link');
  var siteHeader = $('.site-header');
  //var secondarySiteHeader = $('.site-header__nav--secondary');
  var secondarySiteHeaderLink = $('.site-header__nav--secondary .list__link');
  //var secondarySiteHeaderLastItem = $('.site-header__nav--secondary .list__item.last-item .list__link');
  var siteHeaderLink = $('.site-header__nav--primary .list__link');
  var siteHeaderSearch = $('.site-header__search');
  var siteMenuToggle = $('.site-header__toggle');
  var toggle = $('.toggle');
  var toggleButton = $('.toggle__button');





  // Functions

  // Reset global menu
  function resetGlobalMobileMenu() {
    if (header.hasClass('global-mobile-menu-active')) {
      if (matchMedia('(min-width: 760px)').matches) {
        html.removeClass('fixed');
        header.removeClass('global-mobile-menu-active');
        globalHeader.removeClass('global-mobile-menu-active');
        siteHeader.removeClass('global-mobile-menu-active');
        globalMobileMenuToggle.removeClass('active');
      }
    }
  }

  // Reset global search
  function resetGlobalSearch() {
    if (header.hasClass('global-search-active')) {
      if (matchMedia('(max-width: 759px)').matches) {
        html.removeClass('fixed');
        header.removeClass('global-search-active');
        globalHeader.removeClass('global-search-active');
        siteHeader.removeClass('global-search-active');
        globalSearchToggle.removeClass('active');
      }
    }
  }

  // Move sidebar navigation
  function moveSidebarNav() {
    if (matchMedia('(max-width: 759px)').matches) {
      $('.sidebar__nav--primary').prependTo('.main-content');
    } else {
      $('.sidebar__nav--primary').prependTo('.sidebar');
    }
  }

  // Reset global footer toggles
  function resetGlobalFooterToggles() {
    if (globalFooterToggle.hasClass('active')) {
      if (matchMedia('(max-width: 960px)').matches) {
        globalFooterToggle.removeClass('active');
        globalFooterToggleButton.attr('aria-expanded', false);
      }
    } else {
      if (matchMedia('(min-width: 960px)').matches) {
        globalFooterToggle.addClass('active');
        globalFooterToggleButton.attr('aria-expanded', true);
      }
    }
  }

  // Banner videos
  function bannerVideo() {
    var videoURL = $('.banner .embed__iframe').attr('src');

    if ($('.banner .embed__iframe').length) {
      $('.banner .embed__iframe').attr('src', '');

      $('.banner .button--play').on('click', function(e) {
        if (matchMedia('(min-width: 760px)').matches) {
          e.preventDefault();

          $('.banner').addClass('video-active');
          $('.intro').addClass('banner-video-active');

          $('html, body').animate({
            scrollTop: $('.banner').offset().top
          }, 300);

          setTimeout(function() {
            $('.banner .embed__iframe').attr('src', videoURL + '&autoplay=1');
          }, 300);
        }
      });

      $('.banner .button--close').on('click', function(e) { // eslint-disable-line no-unused-vars
        $('.banner .embed__iframe').attr('src', '');

        $('.banner').removeClass('video-active');
        $('.intro').removeClass('banner-video-active');

        setTimeout(function() {
          $('html, body').animate({
            scrollTop: $('body').offset().top
          }, 300);
        }, 300);
      });
    }
  }

  // Debounce so Isotope filtering doesn't happen every millisecond
  function debounce(fn, threshold) {
    var timeout;
    return function debounced() {
      if (timeout) {
        clearTimeout(timeout);
      }
      function delayed() {
        fn();
        timeout = null;
      }
      setTimeout(delayed, threshold || 100);
    };
  }

  // Staff profile card position
  function staffProfileCardPosition() {
    var ww = $(window).width();

    if (ww >= 500) {
      $('.sp__card').insertAfter('.sp__meta');
    } else {
      $('.sp__card').insertAfter('.sp__roles');
    }
  }

  // Cards Carousel Matchheight
  class CardsCarousel {
    constructor ($container) {
      this.$cardsCarousel = $container;
      this.slickOptions = {
        arrows: true,
        dots: true,
        infinite: true,
        mobileFirst: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 679,
            settings: {
              slidesToShow: 3
            }
          }
        ]
      };
    }

    init() {
      const self = this;

      self.$cardsCarousel.each(function(){
        $(this).find('.cards').slick(self.slickOptions); // Slick Carousel
      });
    }
  }

  const cardsCarousel = new CardsCarousel($('.cards-carousel'));




  // On document ready...
  $(document).ready(function() {
    // Run functions
    resetGlobalMobileMenu();
    resetGlobalSearch();
    moveSidebarNav();
    resetGlobalFooterToggles();
    bannerVideo();
    staffProfileCardPosition();
    cardsCarousel.init();



    // Global mobile menu toggle
    globalMobileMenuToggle.on('click', function(e){
      e.preventDefault();

      if ($(this).hasClass('active')) {
        $(this).attr('aria-expanded', function (i, attr) {
          return attr == 'false' ? 'true' : 'false';
        });
        html.removeClass('fixed');
        header.removeClass('global-mobile-menu-active');
        globalHeader.removeClass('global-mobile-menu-active');
        siteHeader.removeClass('global-mobile-menu-active');
        $(this).removeClass('active');
      } else {
        $(this).attr('aria-expanded', function (i, attr) {
          return attr == 'false' ? 'true' : 'false';
        });
        html.addClass('fixed');
        $(this).addClass('active');
        header.addClass('global-mobile-menu-active');
        globalHeader.addClass('global-mobile-menu-active');
        siteHeader.addClass('global-mobile-menu-active');
      }
    });

    globalMobileMenuToggle.add(globalHeaderLink).add(globalHeaderSearch).on('keydown', function(e) {
      if (e.key == "Escape") {
        globalMobileMenuToggle.attr('aria-expanded', 'false');
        html.removeClass('fixed');
        header.removeClass('global-mobile-menu-active');
        globalHeader.removeClass('global-mobile-menu-active');
        siteHeader.removeClass('global-mobile-menu-active');
        globalMobileMenuToggle.removeClass('active');
        globalMobileMenuToggle.focus();
      }
    });





    // Global search toggle
    globalSearchToggle.on('click', function(e){
      e.preventDefault();
      if ($(this).hasClass('active')) {
        html.removeClass('fixed');
        header.removeClass('global-search-active');
        globalHeader.removeClass('global-search-active');
        siteHeader.removeClass('global-search-active');
        $(this).removeClass('active');
      } else {
        html.addClass('fixed');
        header.addClass('global-search-active');
        globalHeader.addClass('global-search-active');
        siteHeader.addClass('global-search-active');
        $(this).addClass('active');
      }
    });



    // Global search switcher
    $('.global-header .form--search .form__radio--all').on('click', function() {
      $('.global-header .form--search').attr('action', 'https://www.lboro.ac.uk/search');
      $('.global-header .form--search .form__field').attr('name', 'q');
      $('.global-header .form--search .form__hidden--collection').attr('value', '');
      $('.global-header .form--search .form__hidden--level').attr('value', '');
    });

    $('.global-header .form--search .form__radio--ug').on('click', function() {
      $('.global-header .form--search').attr('action', 'https://search.lboro.ac.uk/s/search.html');
      $('.global-header .form--search .form__field').attr('name', 'query');
      $('.global-header .form--search .form__hidden--collection').attr('value', 'loughborough-courses');
      $('.global-header .form--search .form__hidden--level').attr('value', 'Undergraduate');
    });

    $('.global-header .form--search .form__radio--masters').on('click', function() {
      $('.global-header .form--search').attr('action', 'https://search.lboro.ac.uk/s/search.html');
      $('.global-header .form--search .form__field').attr('name', 'query');
      $('.global-header .form--search .form__hidden--collection').attr('value', 'loughborough-courses');
      $('.global-header .form--search .form__hidden--level').attr('value', 'Master\'s degrees');
    });

    $('.global-header .form--search .form__radio--phd').on('click', function() {
      $('.global-header .form--search').attr('action', 'https://search.lboro.ac.uk/s/search.html');
      $('.global-header .form--search .form__field').attr('name', 'query');
      $('.global-header .form--search .form__hidden--collection').attr('value', 'loughborough-courses');
      $('.global-header .form--search .form__hidden--level').attr('value', 'PhD opportunities');
    });





    // Add .last-item class to last secondary nav item
    $('.site-header__nav--secondary').each(function() {
      $('.list__item:first-of-type').addClass('first-item');
      $('.list__item:last-of-type').addClass('last-item');
    });


    // Site mobile menu toggle
    siteMenuToggle.on('click', function(e){
      e.preventDefault();
      if ($(this).hasClass('active')) {
        $(this).attr('aria-expanded', function (i, attr) {
          return attr == 'false' ? 'true' : 'false';
        });
        html.removeClass('fixed');
        header.removeClass('site-menu-active');
        globalHeader.removeClass('site-menu-active');
        siteHeader.removeClass('site-menu-active');
        $(this).removeClass('active');
      } else {
        $(this).attr('aria-expanded', function (i, attr) {
          return attr == 'false' ? 'true' : 'false';
        });
        html.addClass('fixed');
        header.addClass('site-menu-active');
        globalHeader.addClass('site-menu-active');
        siteHeader.addClass('site-menu-active');
        $(this).addClass('active');
        $.fn.matchHeight._update();
      }
    });

    /*secondarySiteHeaderLink.on('keydown', function(e) {
      if (e.key == "Tab") {
        if ($(this).parent().hasClass('last-item') === document.activeElement) {
          siteMenuToggle.focus();
          e.preventDefault();
        }
      }
    });*/

    siteMenuToggle.add(siteHeaderSearch).add(siteHeaderLink).add(secondarySiteHeaderLink).on('keydown', function(e) {
      if (e.key == "Escape") {
        siteMenuToggle.attr('aria-expanded', 'false');
        html.removeClass('fixed');
        header.removeClass('site-menu-active');
        globalHeader.removeClass('site-menu-active');
        siteHeader.removeClass('site-menu-active');
        siteMenuToggle.removeClass('active');
        siteMenuToggle.focus();
      }
    });



    // Site header navigation active states
    siteHeaderLink.each(function() {
      var $this = $(this);
      var $thisURL = $(this).attr('href');

      if (url === $thisURL) {
        $this.addClass('active');
      }
    });



    // Sidebar navigation active states
    $('.sidebar__nav--primary').each(function() {
      $('.sidebar__nav--primary .currentbranch0').parent().addClass('active');
      $('.sidebar__nav--primary .currentbranch1').parent().addClass('active');
      $('.sidebar__nav--primary .currentbranch2').parent().addClass('active');
      $('.sidebar__nav--primary .currentbranch3').parent().addClass('active');

      if ($(this).find('.currentbranch3').length) {
        $('.sidebar__nav--primary .currentbranch0').parent().addClass('has-children');
        $('.sidebar__nav--primary .currentbranch1').parent().addClass('has-children');
        $('.sidebar__nav--primary .currentbranch2').parent().addClass('has-children');
      }

      if ($(this).find('.currentbranch2').length) {
        $('.sidebar__nav--primary .currentbranch0').parent().addClass('has-children');
        $('.sidebar__nav--primary .currentbranch1').parent().addClass('has-children');
      }

      if ($(this).find('.currentbranch1').length) {
        $('.sidebar__nav--primary .currentbranch0').parent().addClass('has-children');
      }
    });



    // Primary sidebar navigation
    $('.sidebar__nav--primary .sidebar__heading').on('click', function(){
      if ($(this).hasClass('active')) {
        if (matchMedia('(max-width: 959px)').matches) {
          $(this).removeClass('active');
          $(this).next('.sidebar__container').removeClass('active');
        }
      } else {
        if (matchMedia('(max-width: 959px)').matches) {
          $(this).addClass('active');
          $(this).next('.sidebar__container').addClass('active');
        }
      }
    });

    // Add active class to primary sidebar subheading
    $('.sidebar__nav--primary .sidebar__subheading .sidebar__link[href^="/' + location.pathname.split("/")[1] + '"]').parent().addClass('active');

    // Remove active class from primary sidebar subheading when `currentbranch` class exists
    $('.sidebar__nav--primary span[class^=currentbranch]').each(function() {
      $('.sidebar__nav--primary .sidebar__subheading').removeClass('active');
    });



    // Add table wrapper and scroller classes to tables
    $('table').each(function() {
      var table = $(this);

      $(this).wrap('<div class="table-wrapper"></div>').wrap('<div class="table-scroller"></div>');

      if (table.outerWidth() > table.parent().parent().outerWidth()) {
        table.parent().parent().addClass('has-scroll');
      }

      $(window).on('resize orientationchange', function() {
        if (table.outerWidth() > table.parent().parent().outerWidth()) {
          table.parent().parent().addClass('has-scroll');
        } else {
          table.parent().parent().removeClass('has-scroll');
        }
      });
    });



    // Cards filter
    var cardsKeywordFilter;
    var cardsAreaFilter;

    // Initialise Isotope
    var $cardsGrid = $('.filtered-cards').isotope({
      itemSelector: '.card',
      layoutMode: 'fitRows',
      transitionDuration: 0,

      filter: function() {
        var $this = $(this);
        var cardsKeywordResult = cardsKeywordFilter ? $this.text().match(cardsKeywordFilter): true;
        var cardsAreaResult = cardsAreaFilter ? $this.is(cardsAreaFilter): true;
        return cardsKeywordResult && cardsAreaResult;
      }
    });

    // Filter by keyword (text input)
    var $cardsKeywordFilter = $('.filters--cards #filter-keyword').keyup(debounce(function(){
      cardsKeywordFilter = new RegExp($cardsKeywordFilter.val(), 'gi');
      $cardsGrid.isotope();
    }));

    // Filter by area (buttons)
    $('.filters--cards #filter-area').on('click', 'button', function(e){
      e.preventDefault();
      cardsAreaFilter = $(this).attr('data-filter');
      $cardsGrid.isotope();
    });

    // Change active state of buttons
    $('.filters--cards .form__components--buttons').each(function(i, cardsAreaGroup){
      var $cardsAreaGroup = $(cardsAreaGroup);
      $cardsAreaGroup.on('click', 'button', function() {
        $cardsAreaGroup.find('.active').removeClass('active');
        $(this).addClass('active');
      });
    });



    // Contact cards filter
    var contactCardsKeywordFilter;
    var contactCardsAreaFilter;

    // Initialise Isotope
    var $contactCardsGrid = $('.filtered-contact-cards').isotope({
      itemSelector: '.card',
      layoutMode: 'fitRows',
      transitionDuration: 0,

      filter: function() {
        var $this = $(this);
        var contactCardsKeywordResult = contactCardsKeywordFilter ? $this.text().match(contactCardsKeywordFilter): true;
        var contactCardsAreaResult = contactCardsAreaFilter ? $this.is(contactCardsAreaFilter): true;
        return contactCardsKeywordResult && contactCardsAreaResult;
      }
    });

    // Filter by keyword (text input)
    var $contactCardsKeywordFilter = $('.filters--contact-cards #filter-keyword').keyup(debounce(function(){
      contactCardsKeywordFilter = new RegExp($contactCardsKeywordFilter.val(), 'gi');
      $contactCardsGrid.isotope();
    }));

    // Filter by area (buttons)
    $('.filters--contact-cards #filter-area').on('click', 'button', function(e){
      e.preventDefault();
      contactCardsAreaFilter = $(this).attr('data-filter');
      $contactCardsGrid.isotope();
    });

    // Change active state of buttons
    $('.filters--contact-cards .form__components--buttons').each(function(i, contactCardsAreaGroup){
      var $contactCardsAreaGroup = $(contactCardsAreaGroup);
      $contactCardsAreaGroup.on('click', 'button', function() {
        $contactCardsAreaGroup.find('.active').removeClass('active');
        $(this).addClass('active');
      });
    });



    // Contact cards filter
    var staffListingKeywordFilter;
    var staffListingAreaFilter;

    // Initialise Isotope
    var $staffListingGrid = $('.filtered-staff-listing .list--staff').isotope({
      itemSelector: '.list__item',
      layoutMode: 'vertical',
      transitionDuration: 0,

      filter: function() {
        var $this = $(this);
        var staffListingKeywordResult = staffListingKeywordFilter ? $this.text().match(staffListingKeywordFilter): true;
        var staffListingAreaResult = staffListingAreaFilter ? $this.is(staffListingAreaFilter): true;
        return staffListingKeywordResult && staffListingAreaResult;
      }
    });

    // Filter by keyword (text input)
    var $staffListingKeywordFilter = $('.filters--staff-listing #filter-keyword').keyup(debounce(function(){
      staffListingKeywordFilter = new RegExp($staffListingKeywordFilter.val(), 'gi');
      $staffListingGrid.isotope();
    }));

    // Filter by area (buttons)
    $('.filters--staff-listing #filter-area').on('click', 'button', function(e){
      e.preventDefault();
      staffListingAreaFilter = $(this).attr('data-filter');
      $staffListingGrid.isotope();
    });

    // Change active state of buttons
    $('.filters--staff-listing .form__components--buttons').each(function(i, staffListingAreaGroup){
      var $staffListingAreaGroup = $(staffListingAreaGroup);
      $staffListingAreaGroup.on('click', 'button', function() {
        $staffListingAreaGroup.find('.active').removeClass('active');
        $(this).addClass('active');
      });
    });



    // Magnific popup
    $('.iframe-link').magnificPopup({
      disableOn: 600,
      type: 'iframe',
      gallery: {
        enabled: true
      }
    });

    $('.image-link').magnificPopup({
      type: 'image',
      gallery: {
        enabled: true
      }
    });



    // Remove spaces from telephone number links
    if ($('.tel-link').length) {
      var trimTelLinkHref = $('.tel-link').attr('href').replace(/ /g,'');
      $('.tel-link').attr('href', trimTelLinkHref );
    }



    // Toggle
    toggleButton.on('click', function(e){
      e.preventDefault();
      $(this).parent(toggle).toggleClass('active');
      $(this).attr('aria-expanded', function (i, attr) {
        return attr == 'false' ? 'true' : 'false';
      });
    });



    // Global footer map markers
    $('.footer__marker--lboro').hover(
      function() {
        if (matchMedia('(min-width: 960px)').matches) {
          $('.footer__location--lboro').addClass('active');
        }
      }, function() {
        if (matchMedia('(min-width: 960px)').matches) {
          $('.footer__location--lboro').removeClass('active');
        }
      }
    );

    $('.footer__marker--london').hover(
      function() {
        if (matchMedia('(min-width: 960px)').matches) {
          $('.footer__location--london').addClass('active');
        }
      }, function() {
        if (matchMedia('(min-width: 960px)').matches) {
          $('.footer__location--london').removeClass('active');
        }
      }
    );

    $('.footer__location--lboro').hover(
      function() {
        if (matchMedia('(min-width: 960px)').matches) {
          $('.footer__marker--lboro').addClass('active');
        }
      }, function() {
        if (matchMedia('(min-width: 960px)').matches) {
          $('.footer__marker--lboro').removeClass('active');
        }
      }
    );

    $('.footer__location--london').hover(
      function() {
        if (matchMedia('(min-width: 960px)').matches) {
          $('.footer__marker--london').addClass('active');
        }
      }, function() {
        if (matchMedia('(min-width: 960px)').matches) {
          $('.footer__marker--london').removeClass('active');
        }
      }
    );



    // Global footer header toggles
    /*$('.footer__heading').on('click', function(){
      if ($(this).hasClass('active')) {
        if (matchMedia('(max-width: 959px)').matches) {
          $(this).removeClass('active');
          $(this).next('.footer__content').removeClass('active');
        }
      } else {
        if (matchMedia('(max-width: 959px)').matches) {
          $(this).addClass('active');
          $(this).next('.footer__content').addClass('active');
        }
      }
    });*/




    //
    // Add no events class to events container if there aren't any
    // Trim whitespace first and then check for text
    //
    if ($('.home-events__body').length) {
      if ($.trim($('.home-events__body').text()).length === 0) {
        $('.home-events').addClass('home-events--no-events');
        $('.section-header__heading--events').addClass('no-events');
        $('.home-news-events').addClass('no-events');
      }
    }





    // Back to top
    $('.footer__top').on('click', function(e){
      e.preventDefault();

      $('html, body').animate({
        scrollTop: $('body').offset().top
      }, 750);
    });
  });





  // On window load...
  $(window).on('load', function(){
    // Match height
    $('.links-list .lead-paragraph').matchHeight();
    $('.list--news-categories a').matchHeight();
    $('.content-type--contact.content-type--inline .contact').matchHeight();

    // Page loaded
    $('body').addClass('page-loaded');
  });





  // On window resize...
  $(window).on('resize', function(){
    // Run functions
    resetGlobalMobileMenu();
    resetGlobalSearch();
    moveSidebarNav();
    resetGlobalFooterToggles();
    staffProfileCardPosition();
  });


  // Add to calendar button.
  /*
  if ( $('.atcb').length > 0 ) {
    atcb_init();
  }
  */


})(jQuery);
